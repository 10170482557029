import React from 'react'
import { Container } from 'react-bootstrap'
import { Route, Switch } from 'react-router-dom'
import Home from './Home'
import Statistics from './Statistics'

export default class Content extends React.Component {
  render() {
    return (
      <Container className={'pb-5'} id={'page-content'} fluid>
        <Switch>
          <Route path={'/stats'}>
            <Statistics />
          </Route>
          <Route path={'/'}>
            <Home />
          </Route>
        </Switch>
      </Container>
    )
  }
}
