import React from 'react'
import { Col, Image, Jumbotron, Row } from 'react-bootstrap'

export default class Home extends React.Component {
  render() {
    return (<>
        <article>
          <Row>
            <Col xs={12}><h1>Welcome</h1>
              <p>
                This site is a personal log of my gardening adventures in my flat / balcony.
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs={12}><h2>Latest Image</h2></Col>
            <Col xs={12}><Image src={'https://plants.christian-renold.com/images/1/latest.jpg'} fluid /></Col>
          </Row>
        </article>
        <article>
          <Col xs={12}><h2>Timelapse Playlist</h2></Col>
          <Col xs={12}>
            <iframe src="https://www.youtube.com/embed/videoseries?list=PLP1LT_XOQU8qm2mNmqzTHgKv7TeSsx6a4"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen=""
                    width="960" height="720" frameBorder="0"></iframe>
          </Col>
        </article>
      </>

    )
  }
}
