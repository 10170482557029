import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Brush, CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import moment from 'moment'
import { loadSensorOverview } from '../actions'
import { connect } from 'react-redux'

class Statistics extends React.Component {

  componentDidMount() {
    this.loadOverview()
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.loadOverview()
  }

  loadOverview() {
    if (!this.props.sensorOverview.data && !this.props.sensorOverview.error && !this.props.sensorOverview.loading) {
      console.log('load overview from stat comp.')
      this.props.loadSensorOverview()
    }
  }

  renderLoading() {
    return <Col xs={12}>
      <h3 className="title has-text-centered"><i className={'fas fa-spinner fa-spin'} /> loading data...</h3>
    </Col>
  }

  renderError() {
    return <Col xs={12}>
      <h3 className="title has-text-centered">Error</h3>
      <p className={'text-danger'}>{this.props.sensorOverview.error}</p>
    </Col>
  }

  renderLoaded() {
    return <>
      <Col xs={12}><h2>Temperature</h2></Col>
      <Col xs={12}>
        <ResponsiveContainer width={'100%'} height={300}>
          <LineChart data={this.props.sensorOverview.data}
                     margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                     syncId="sensorOverview">
            <XAxis dataKey="ts" tickFormatter={(unixTime) => moment(unixTime * 1000).format('dd HH:mm')}/>
            <YAxis  type="number" domain={['dataMin - 1', 'dataMax + 1']} />
            <CartesianGrid strokeDasharray="5 5" />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="temp_dht11" stroke="#8884d8" connectNulls dot={false} />
            <Line type="monotone" dataKey="temp_am2302" stroke="#82ca9d" connectNulls dot={false} />
            <Brush />
          </LineChart>
        </ResponsiveContainer>
      </Col>
      <Col xs={12}><h2>Humidity</h2></Col>
      <Col xs={12}>
        <ResponsiveContainer width={'100%'} height={300}>
          <LineChart data={this.props.sensorOverview.data}
                     margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                     syncId="sensorOverview">
            <XAxis dataKey="ts" tickFormatter={(unixTime) => moment(unixTime * 1000).format('hh:mm')} />
            <YAxis  type="number"   />
            <CartesianGrid strokeDasharray="5 5" />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="humi_dht11" stroke="#8884d8" connectNulls  dot={false}/>
            <Line type="monotone" dataKey="humi_am2302" stroke="#82ca9d" connectNulls dot={false} />
          </LineChart>
        </ResponsiveContainer>
      </Col>
    </>
  }

  renderContent() {
    if (!this.props.sensorOverview || this.props.sensorOverview.loading === true) {
      return this.renderLoading()
    } else if (this.props.sensorOverview.error) {
      return this.renderError()
    } else {
      return this.renderLoaded()
    }
  }

  render() {
    return (
      <article>
        <Row>
          <Col xs={12}><h1>Statistics</h1></Col>
          <Col xs={12} className={'mb-5'}>
            Every 5 minutes two sensors (<strong>AM2302</strong> and <strong>DHT-11</strong>) measure temperature and
            humidity.
          </Col>
          {this.renderContent()}
        </Row>
      </article>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    loadSensorOverview: () => dispatch(loadSensorOverview()),
  }
}

const mapStateToProps = state => {
  return { sensorOverview: state.sensorOverview }
}

export default connect(mapStateToProps, mapDispatchToProps)(Statistics)
