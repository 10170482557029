import React from 'react'
import { Link } from 'react-router-dom'
import { Navbar, Nav, Container } from 'react-bootstrap'

export default class Header extends React.Component {

  render() {
    return <header className="app-header">
      <Navbar expand={'md'} bg="dark" variant={'dark'}>
        <Container fluid>
          <Navbar.Brand as={Link} to="/">
            <img alt="" src="/logo-small.png" width="30" height="30" className="d-inline-block align-top" /> {' '}
            Plants!
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse variant={'dark'} className="justify-content-end navbar-nav" id="responsive-navbar-nav">
            <Nav.Link as={Link} to="/">Home</Nav.Link>
            <Nav.Link as={Link} to="/stats">Statistics</Nav.Link>
            <Nav.Link className={'link-disabled-hover'} title={'coming soon'} alt={'coming soon'} to="/blog">Blog</Nav.Link>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  }
}


