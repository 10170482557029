import { call, put, takeLatest } from 'redux-saga/effects'
import { ACTIONS, loadSensorOverviewFailure, loadSensorOverviewSuccess } from '../actions'
import { fetchUrl } from '../lib/helpers'

const BASE_URL = process.env.REACT_APP_API_BASE

export default [
  takeLatest(ACTIONS.LOAD_SENSOR_OVERVIEW, workerFetchSensorOverview),
]

function* workerFetchSensorOverview() {
  try {
    console.log('worker fetch sensor overview')
    const url = `${BASE_URL}/plantpi.php`
    const response = yield call(fetchUrl, url)
    yield put(loadSensorOverviewSuccess(response.data))
  } catch (error) {
    console.log('error loading sensor overview', error)
    yield put(loadSensorOverviewFailure(error))
  }
}
