import React from 'react'
import { Col, Container, Navbar, Row } from 'react-bootstrap'

export default class Footer extends React.Component {

  render() {
    return <Navbar sticky="bottom" bg="dark" variant={'dark'} id={'footer-nav'}>
      <Container fluid>
        <Row>
          <Col xs={12} className={'text-white'}>
            &copy; 2020 - <a href={'https://crabs.dev'}
                             target='_blank'
                             rel='noopener noreferrer'>Crabs</a> - All rights reserved
          </Col>
        </Row>
      </Container>
    </Navbar>
  }
}
