import { ACTIONS } from '../actions'

const sensorOverview = (state = { loading: false }, action) => {
  switch (action.type) {
    case ACTIONS.LOAD_SENSOR_OVERVIEW:
      console.log('reducer load')
      return { loading: true }
    case ACTIONS.LOAD_SENSOR_OVERVIEW_SUCCESS:
      console.log('reducer load ok')
      return { loading: false, data: action.data.reverse() }
    case ACTIONS.LOAD_SENSOR_OVERVIEW_FAILURE:
      console.log('reducer load err')
      return { loading: false, error: action.error }
    default:
      return state
  }
}

export default sensorOverview
