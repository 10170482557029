export const ACTIONS = {
  LOAD_SENSOR_OVERVIEW: 'LOAD_SENSOR_OVERVIEW',
  LOAD_SENSOR_OVERVIEW_SUCCESS: 'LOAD_SENSOR_OVERVIEW_SUCCESS',
  LOAD_SENSOR_OVERVIEW_FAILURE: 'LOAD_SENSOR_OVERVIEW_FAILURE',
}

export const loadSensorOverview = () => ({
  type: ACTIONS.LOAD_SENSOR_OVERVIEW,
})

export const loadSensorOverviewFailure = error => ({
  type: ACTIONS.LOAD_SENSOR_OVERVIEW_FAILURE,
  error: error,
})

export const loadSensorOverviewSuccess = data => ({
  type: ACTIONS.LOAD_SENSOR_OVERVIEW_SUCCESS,
  data: data,
})
